import React, { useState } from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";
import { UnityAPI } from "~components/api-data";

import {
  Code,
  Response,
  Attribute,
  IL,
  VSpace,
  SideBySideCode,
  APIMethod,
  APIReferenceiTHOR,
} from "~components/documentation";

export default function iTHORDocReference({ data, location }) {
  const [highlightedParam, setHighlightedParam] = useState("");
  return (
    <APIReferenceiTHOR
      columnKey="object-state-changes"
      pageName="Object State Changes"
    >
      <Section sectionTitle="Open Object" emoji="book">
        <APIMethod
          location={location}
          methodKey="OpenObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="OpenObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Close Object" emoji="closed_book">
        <APIMethod
          location={location}
          methodKey="CloseObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Break Object" emoji="broken_heart">
        <APIMethod
          location={location}
          methodKey="BreakObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="BreakObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Cook Object" emoji="cooking">
        <APIMethod
          location={location}
          methodKey="CookObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="CookObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Slice Object" emoji="avocado">
        <APIMethod
          location={location}
          methodKey="SliceObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="SliceObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Toggle Object" emoji="flashlight">
        <APIMethod
          location={location}
          methodKey="ToggleOn"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="ToggleObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Dirty Object" emoji="stew">
        <APIMethod
          location={location}
          methodKey="DirtyObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="DirtyObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Fill Object with Liquid" emoji="tea">
        <APIMethod
          location={location}
          methodKey="FillObjectWithLiquid"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="FillObjectWithLiquidResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Empty Liquid from Object" emoji="cup_with_straw">
        <APIMethod
          location={location}
          methodKey="EmptyLiquidFromObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="EmptyLiquidFromObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Use Up Object" emoji="dango">
        <APIMethod
          location={location}
          methodKey="UseUpObject"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="15px" />
        <APIMethod
          location={location}
          methodKey="UseUpObjectResponse"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
    </APIReferenceiTHOR>
  );
}
